import React from "react"
import Helmet from "react-helmet"
import { MDXProvider } from "@mdx-js/react"

const Shell = (props) => {
  return(
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>E-Learning und KI</title>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&family=Source+Serif+Pro:wght@300;400;700&display=swap" rel="stylesheet" />
      </Helmet>

      <MDXProvider>
        {props.children}
      </MDXProvider>
      
    </div>
  )
}

export default Shell