import React from 'react'

const Container = (props) => {
  return (
    <div className='md:mx-auto container md:max-w-screen-lg px-4 md:px-6'>
      {props.children}
    </div>
  )
}

export default Container